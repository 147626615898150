import { render, staticRenderFns } from "./CreateUserForm.vue?vue&type=template&id=0290c5c0"
import script from "./CreateUserForm.vue?vue&type=script&lang=js"
export * from "./CreateUserForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports