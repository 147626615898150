<template>
  <div class="image-upload">
    <v-tabs
      v-model="tab"
      align-with-title
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
        File Uploader
      </v-tab>
      <v-tab>
        Folder Uploader
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-select
          :items="this.allFolders"
          v-model="selectedStructure"
          item-text="path"
          item-value="id"
          label="Select a Structure"
        ></v-select>
        <div class="buzz-drop-zone"
             @click="selectFile"
             :class="{ dragover: isDragOver }"
             @dragover.prevent="onDragOver"
             @dragleave="onDragLeave"
             @drop.prevent="onDrop">
          <p>Drag & Drop files here (only support JPEG)</p>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div style="text-align:center;">
          <div>
            <v-checkbox
              v-model="createNewStructure"
              label="create new structure with folder name"
              />
            <v-select
              :disabled="createNewStructure"
              :items="this.allFolders"
              v-model="selectedStructure"
              item-text="path"
              item-value="id"
              label="Select a Structure"
            ></v-select>
          </div>

          <v-btn
            color="primary"
            class="ma-2 white--text"
            @click="selectFolder"
          >
            Select a Folder
            <v-icon
              right
              dark
            >
              mdi-folder
            </v-icon>
          </v-btn>
          <input style="display:none;" type="file"
                 name="fileList"
                 id="folderUploadInput"
                 @change="onFolderSelected"
                 webkitdirectory multiple />
        </div>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      v-model="fileTableDialog"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline" v-if="!isError">
            {{this.files.length}} files will be uploaded to structure
            <strong>{{selectedStructureName}}</strong></span>
          <span class="headline" v-else>
            The following files failed to upload
          </span>
        </v-card-title>
          <v-card-text>
            <div>{{uploadStatus}}</div>
            <div v-if="isUploading">
              <v-progress-linear
                indeterminate
                color="primary"
              />
              Upload Progress: {{uploadProgress}}
            </div>
            <v-data-table
              :headers="fileTableHeader"
              :items="files"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:item.status="{ item }">
                <v-progress-circular
                  v-if="status[item.uid]?.status === 'uploading'"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <v-icon v-else-if="status[item.uid]?.status=== 'uploaded'" color="green">
                  mdi-check-circle
                </v-icon>
                <v-icon v-else-if="status[item.uid]?.status === 'failed'" color="red">
                  mdi-alert-circle
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        <v-card-actions style="justify-content: flex-end">
          <v-btn @click="closeFileTableDialog" :disabled="isUploading">Cancel</v-btn>
          <v-btn @click="handleUpload" v-if="!isError" color="primary"
                 :disabled="isUploading">Upload</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>
<script>

import Uploader from '@components/images/utilities/Uploader';
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'V2Uploader',
  components: {
  },
  props: ['cid', 'pid'],
  data() {
    return {
      fileTableDialog: false,
      showOverlay: false,
      processedPercent: 0,
      createNewStructure: false,
      isUploading: false,
      uploadStatus: '',
      tab: null,
      uploadProgress: '',
      selectedStructure: null,
      isDragOver: false,
      folderName: null,
      isError: false,
      status: {},
      fileTableHeader: [
        { text: 'Status', value: 'status', width: '100px' },
        { text: 'File Name', value: 'name' },
        { text: 'Size', value: 'size' },
      ],
      files: [],
    };
  },
  computed: {
    ...mapGetters(['allFolders', 'currentProject', 'currentCompany']),
    selectedStructureName() {
      return this.allFolders.find((folder) => folder.id === this.selectedStructure)?.path;
    },
  },
  methods: {
    ...mapActions(['getProject', 'getImagesByProject', 'createFolder']),
    closeFileTableDialog() {
      this.files = [];
      this.fileTableDialog = false;
    },
    selectFile() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/jpeg';
      input.multiple = true;
      input.onchange = (e) => {
        this.handleFiles(e.target.files);
      };
      input.click();
    },
    selectFolder() {
      document.getElementById('folderUploadInput').click();
    },
    createStructure(folderName, count) {
      const payload = {
        company_id: this.cid,
        project_id: this.pid,
        data: {
          pid: this.currentProject.firestore_pid,
          cid: this.currentCompany.firestore_cid,
          structure: {
            name: folderName,
            structuretype_id: 0,
            lat: null,
            long: null,
            severity: null,
            project_id: this.currentProject.pid,
            line_id: null,
            work_order: false,
            reviewed: false,
            second_reviewer: null,
            note: '',
            image_count: count,
            complete_inspection: false,
            cid: this.currentCompany.firestore_cid,
            date: moment(),
            processed_image_count: 0,
            clustering_uncertainty: false,
            field_inspection: false,
          },
        },
      };
      return this.createFolder(payload);
    },

    async onFolderSelected(e) {
      const folderFiles = e.target.files;
      const name = folderFiles[0].webkitRelativePath.split('/')[0];
      this.folderName = name;
      this.handleFiles(folderFiles);
    },
    onDragOver() {
      this.isDragOver = true;
    },
    onDragLeave() {
      this.isDragOver = false;
    },
    onDrop(event) {
      this.isDragOver = false;
      this.handleFiles(event.dataTransfer.files);
    },
    handleFiles(files) {
      if (!this.selectedStructure || !this.selectedStructureName) {
        alert('Please select a structure first');
        return;
      }
      const jpegFiles = [...files].filter((file) => file.type === 'image/jpeg');
      const filesToUpload = jpegFiles.map((file) => ({
        uid: uuidv4(),
        name: file.name,
        size: this.formatBytes(file.size, 2),
        fileObj: file,
      }));
      this.files = filesToUpload;
      this.fileTableDialog = true;
    },
    async handleUpload() {
      try {
        if (this.createNewStructure) {
          try {
            const { data } = await this.createStructure(this.folderName, this.files.length);
            this.selectedStructure = data.id;
          } catch (err) {
            console.error('failed to create new structure', err);
          }
        }
        if (!this.selectedStructure) {
          return;
        }
        const uploadWorker = new Uploader({
          images: this.files,
          sid: this.selectedStructure,
          project_id: this.pid,
        });
        const failedFiles = [];
        this.uploadProgress = `0/${this.files.length} files uploaded`;
        uploadWorker.updateStatus = async (payload) => {
          this.status = payload;
          this.uploadProgress = `${payload.handled}/${payload.total} files uploaded`;
          if (payload.handled === payload.total) {
            await this.getImagesByProject(
              { cid: this.currentCompany.cid, pid: this.currentProject.pid },
            );
            if (failedFiles.length > 0) {
              this.isError = true;
              this.uploadStatus = 'upload completed with some failed files';
              this.files = failedFiles;
            } else {
              this.uploadStatus = 'upload completed';
              this.fileTableDialog = false;
            }
            this.isUploading = false;
          }
        };

        uploadWorker.onError = (payload) => {
          failedFiles.push(payload);
        };
        this.isUploading = true;
        await uploadWorker.startUpload();
      } catch (error) {
        this.isUploading = false;
        this.uploadStatus = 'Can not upload files, please try again later.';
        console.error(error);
      }
    },
    formatBytes(bytes, decimals) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals || 2;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return `${parseFloat((bytes / Math.exp(i * Math.log(k))).toFixed(dm))} ${sizes[i]}`;
    },

  },
  watch: {
    fileTableDialog(newVal) {
      if (!newVal) {
        this.files = [];
        this.uploadProgress = '';
        this.uploadStatus = '';
        this.isError = false;
        this.createNewStructure = false;
        this.folderName = '';
      }
    },
  },
  created() {
    const { pid } = this;
    if (!this.currentProject || Object.keys(this.currentProject).length === 0) this.getProject(pid);
  },
  mounted() {
    const misFolder = this.allFolders.find((f) => f.path === 'Miscellaneous');
    this.selectedStructure = misFolder?.id;
  },
};
</script>

<style scoped>
.display-none{
  display: none;
}
.buzz-drop-zone{
  width: 100%;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  color: #666;
  background-color: #fff;
  transition: background-color 0.3s, border-color 0.3s;
}
.buzz-drop-zone.dragover{
  background-color: #e1e1e1;
  border-color: #aaa;
  animation: pulse 1s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
