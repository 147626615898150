import users from '@services/users';
import auth from '@services/auth';
import images from '@services/images';
import folders from '@services/folders';
import ml from '@services/ml';
import dashboard from '@services/dashboard';
import projects from '@services/projects';
import companies from '@services/companies';
import teams from '@services/teams';
import storage from '@services/storage';
import processed from '@services/processed-images';
import support from '@services/support';
import release from '@services/release';
import email from '@services/email';

export default {
  users,
  auth,
  images,
  folders,
  ml,
  dashboard,
  projects,
  companies,
  teams,
  storage,
  processed_images: processed,
  support,
  release,
  email,
};
